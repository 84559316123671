import NavBar from "./NavBar";
import HomePage from "./pages/Home";
import AboutPage from "./pages/About";
import ContactPage from "./pages/Contact";
import ProductsPage from "./pages/Products";
import { Routes, Route } from "react-router-dom";
function App() {
  return (
    <div className="m-0 p-0 bg-white text-slate-800 w-screen flex flex-col items-center">
      <NavBar/>
      <div className="lg:max-w-[1160px] px-2 md:px-4 w-full h-full overflow-y-auto grow">
        <Routes>
          <Route path="/" element={ <HomePage/> } />
          <Route path="/products" element={ <ProductsPage/> } />
          <Route path="/about" element={ <AboutPage/> } />
          <Route path="/contact-us" element={ <ContactPage/> } />
        </Routes>
      </div>

      <div className="m-0 mt-6 w-full bg-black text-white ">
        <div className="container mx-auto py-2 px-4 grid grid-cols-1 sm:grid-cols-3 gap-1 sm:gap-2 md:gap-3">
          <div className="p-2">
            <div className="text-xl font-bold">HATORI IMPORT EXPORT COMPANY LIMITED</div>
            <div className="text-xs">© 2024 Hatori</div>
          </div>

          <div className="p-2 ">
            <div className="text-xl font-bold">CONTACT US</div>
            <div className="mt-3 text-xs"><i>9th Floor, Rox Tower,180-192 Nguyen Cong Tru Street, Nguyen Thai Binh Ward, District 1, Ho Chi Minh City, Vietnam</i></div>
            <div className="mt-2 text-xs"><a href='http://www.hatori.vn'>www.hatori.vn</a></div>
            <div className="mt-2 text-xs"><a href="tel:+84798082881">+84 798082881</a> /  <a href="tel:+84934113242">+84 934113242</a> </div>  
            <div className="mt-2 text-xs"><a href="mailto:adela@hatori.vn<">adela@hatori.vn</a> / <a href="mailto:helen@hatori.vn<">helen@hatori.vn</a></div>
              
          </div>

          <div className="p-2 ">
            <div className="text-xl font-bold">FOLLOW US</div>
            <div className="flex mt-2">
              <a href="" rel="noreferrer" className="mr-4">
                <img src="/fb.png" alt="facebook" className="w-8 h-8"/>
              </a>
              <a href="" rel="noreferrer" className="mr-4">
                <img src="/instagram.png" alt="instagram" className="w-8 h-8"/>
              </a>
              <a href="" rel="noreferrer" className="mr-4">
                <img src="/linkedin.png" alt="linkedin" className="w-8 h-8"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
