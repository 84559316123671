
export const items = [
    {
        id: "canned_products_1",
        name: 'Canned Pineapple: Dứa',
        type: 'Slices, Pieces, Tidbits, Chunks, Cube,…',
        packaging: "15 Oz/ can, 24 cans/ box",
        weight: 'N/W 450gr, D/W at least 50-52%',
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '24 months',
        origin: 'Vietnam'
    },
    {
        id: "canned_products_2",
        name: 'Pickled Cucumber: Dưa Chuột Ngâm',
        packaging: "720ml/ jar, 12jar/ box",
        size: '4 - 7cm, 7 - 10cm',
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '24 months',
        origin: 'Vietnam'
    },
    {
        id: "canned_products_3",
        name: 'Canned Whole Baby Corn: Bắp Non Nguyên Trái',
        packaging: "15 Oz/ can, 24 cans/ box",
        size: '12, 15, 18 pieces up/ can',
        weight: 'N/W 450gr, D/W at least 230g',
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '24 months',
        origin: 'Vietnam'
    },
    {
        id: "canned_products_4",
        name: 'Canned Tomatoes: Cà Chua Ngâm',
        packaging: "720ml/ jar, 12jars/ box",
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '24 months',
        origin: 'Vietnam'
    },
    {
        id: "canned_products_5",
        name: 'Canned Quail Eggs: Trứng Cút',
        packaging: "15 Oz/ can, 24 cans/ box",
        size: '20 eggs/ can',
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '24 months',
        origin: 'Vietnam'
    }
]