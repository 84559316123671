
export const items = [
    {
        id: "fresh_product_1",
        name: 'Fresh Diamond Shape Coconut: Dừa Gọt Kim Cương',
        packaging: "9pcs/ box (800g - 1.2kgs/ pcs)",
        storage: '2 - 5°C',
        shelf_life: '50 - 60 days',
        origin: 'Vietnam'
    },
    {
        id:"fresh_product_2",
        name: 'Fresh Coconut Two Top Cutting: Dừa Gọt Hai Đầu',
        packaging: "9pcs/ box (800g - 1.2kgs/ pcs)",
        storage: '2 - 5°C',
        shelf_life: '50 - 60 days',
        origin: 'Vietnam'
    },
    {
        id: "fresh_product_3",
        name: 'Fresh Young Green Coconut: Dừa Tươi Nguyên Trái',
        packaging: "9pcs/ box (800g - 1.2kgs/ pcs)",
        storage: '2 - 5°C',
        shelf_life: '50 - 60 days',
        origin: 'Vietnam'
    },
    {
        id: "fresh_product_4",
        name: 'Fresh Passion Fruit: Chanh Dây',
        packaging: "500g/ tray, 10 trays/ box (as Buyer's request)",
        storage: '13 - 14°C',
        shelf_life: '20 days',
        origin: 'Vietnam'
    },
    {
        id: "fresh_product_5",
        name: 'Fresh Whole Lemongrass: Sả',
        packaging: "1kg/ bag, 10 bags/ box (as Buyer's request)",
        storage: '13 - 14°C',
        shelf_life: '15-20 days',
        origin: 'Vietnam'
    },
    {
        id: "fresh_product_6",
        name: 'Fresh Rambutan: Chôm Chôm',
        packaging: "500g/ tray, 10 trays/ box",
        storage: '13 - 14°C',
        shelf_life: '7 days',
        origin: 'Vietnam'
    },
    {
        id: "fresh_product_7",
        name: 'Fresh Pomelo Pink Flesh: Bưởi Ruột Hồng',
        packaging: "8kgs/ box (as Buyer's request)",
        storage: '13 - 14°C',
        shelf_life: '60 days',
        origin: 'Vietnam'
    },
    {
        id: "fresh_product_8",
        name: 'Fresh Pomelo Yellow Flesh: Bưởi 5 Roi',
        packaging: "8kgs/ box (as Buyer's request)",
        storage: '13 - 14°C',
        shelf_life: '60 days',
        origin: 'Vietnam'
    },
    {
        id: "fresh_product_9",
        name: 'Fresh Dragon Fruit White Flesh: Thanh Long Ruột Trắng',
        packaging: "4kgs/ box (9 - 10pcs)  (as Buyer's request)",
        storage: '3 - 5°C',
        shelf_life: '45 days',
        origin: 'Vietnam'
    },
    {
        id: "fresh_product_10",
        name: 'Fresh Dragon Fruit Red Flesh: Thanh Long Ruột đỏ',
        packaging: "4kgs/ box (9 - 10pcs)  (as Buyer's request)",
        storage: '3 - 5°C',
        shelf_life: '30 days',
        origin: 'Vietnam'
    },
    {
        id: "fresh_product_11",
        name: 'Fresh Guava White Flesh: Ổi Ruột Trắng',
        packaging: "9kgs/ box (as Buyer's request)",
        storage: '3 - 5°C',
        shelf_life: '45 days',
        origin: 'Vietnam'
    },
    {
        id: "fresh_product_12",
        name: 'Fresh Guava Pink Flesh: Ổi Ruột Hồng',
        packaging: "9kgs/ box (as Buyer's request)",
        storage: '3 - 5°C',
        shelf_life: '25 days',
        origin: 'Vietnam'
    }
]