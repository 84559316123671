

export const items = [
    {
        id: "dried_product_1",
        name: 'Dried Pineapple: Dứa Sấy Dẻo',
        packaging: "500g/ bag, 20 bags/ box (as Buyer's request)",
        type: 'Soft Dried',
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '12 months',
        origin: 'Vietnam'
    },
    {
        id: "dried_product_2",
        name: 'Dried Mango: Xoài Sấy Dẻo/ Sấy Giòn',
        packaging: "500g/ bag, 20 bags/ box (as Buyer's request)",
        type: 'Soft Dried, Snack',
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '12 months',
        origin: 'Vietnam'
    },
    {
        id: "dried_product_3",
        name: 'Dried Lotus Roots: Củ Sen Sấy Giòn',
        packaging: "500g/ bag, 20 bags/ box",
        type: 'Snack',
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '12 months',
        origin: 'Vietnam'
    },
    {
        id:"dried_product_4",
        name: 'Dried Dragon Fruit: Thanh Long Sấy Dẻo',
        packaging: "500g/ bag, 20 bags/ box",
        type: 'Soft Dried',
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '12 months',
        origin: 'Vietnam'
    },
    {
        id: "dried_product_5",
        name: 'Dried Banana: Chuối Sấy Dẻo/ Sấy Giòn',
        packaging: "500g/ bag, 20 bags/ box",
        type: 'Soft Dried, Snack',
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '12 months',
        origin: 'Vietnam'
    },
    {
        id: "dried_product_6",
        name: 'Dried Pomelo Peel: Vỏ Bưởi Sấy Dẻo/ Sấy Giòn',
        packaging: "500g/ bag, 20 bags/ box",
        type: 'Soft Dried, Snack',
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '12 months',
        origin: 'Vietnam'
    },
    {
        id: "dried_product_7",
        name: 'Dried Coconut Chips: Dừa Sấy Giòn',
        packaging: "500g/ bag, 20 bags/ box",
        type: 'Soft Dried, Snack',
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '12 months',
        origin: 'Vietnam'
    },
    {
        id: "dried_product_8",
        name: 'Dried Lotus seeds: Hạt sen sấy',
        packaging: "500g/ bag or as Buyer's request",
        type: 'Dried',
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '12 months',
        origin: 'Vietnam'
    },
    {
        id: "dried_product_9",
        name: 'Dried Jackfruit: Mít sấy',
        packaging: "500g/ bag or as Buyer's request",
        type: 'Dried',
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '12 months',
        origin: 'Vietnam'
    }
]