const ContactUs = () => {

    return <div className="h-full w-full grid place-content-center p-2 sm:p-4">
        <div className="mt-2 flex items-center justify-center">
            <img className="w-full max-w-[600px] mb-4" src='/contact-us.jpg' alt='Haroti - Contact Us'></img>
        </div>
        <div className="mt-2 p-4 text-center text-slate-800 bg-slate-50 rounded-lg">
            <div className="text-xl font-bold text-slate-900">HATORI IMPORT EXPORT COMPANY LIMITED</div>
            <div className="mt-3">Address: <i>9th Floor, Rox Tower,180-192 Nguyen Cong Tru Street, Nguyen Thai Binh Ward, District 1, Ho Chi Minh City, Vietnam</i></div>
            <div className="mt-3">Website: <a href='http://www.hatori.vn'>www.hatori.vn</a></div>
            <div className="mt-3">Tel.: <a href="tel:+84798082881">+84 798082881</a> /  <a href="tel:+84934113242">+84 934113242</a> </div>  
            <div className="mt-3">Email: <a href="mailto:adela@hatori.vn<">adela@hatori.vn</a> / <a href="mailto:helen@hatori.vn<">helen@hatori.vn</a></div>
            
        </div>
    </div>
}

export default ContactUs