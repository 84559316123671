

export const items = [
    {
        id: "drink_product_1",
        name: 'Tea: Trà',
        packaging: "as Buyer's request",
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '24 months',
        origin: 'Vietnam'
    },
    {
        id: "drink_product_2",
        name: 'Coffee: Cà Phê',
        packaging: "as Buyer's request",
        type: 'Raw, Instant,…',
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '24 months',
        origin: 'Vietnam'
    },
    {
        id: "drink_product_3",
        name: 'Alcohol: Đồ Uống Có Cồn',
        packaging: "as Buyer's request",
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '24 months',
        origin: 'Vietnam'
    }
]