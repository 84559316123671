
export const items = [
    {
        id: "other_products_1",
        name: 'Rice Vermicelli: Bún Gạo',
        packaging: "as Buyer's request",
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '24 months',
        origin: 'Vietnam'
    },
    {
        id: "other_products_2",
        name: 'Rice Noodle: Hủ Tiếu/ Phở',
        packaging: "as Buyer's request",
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '24 months',
        origin: 'Vietnam'
    },
    {
        id: "other_products_3",
        name: 'Rice Paper: Bánh Tráng',
        packaging: "as Buyer's request",
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '12 months',
        origin: 'Vietnam'
    },
    {
        id: "other_products_4",
        name: 'Spices: Gia Vị',
        packaging: "as Buyer's request",
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '24 months',
        origin: 'Vietnam'
    },
    {
        id: "other_products_5",
        name: 'Sauces: Nước Xốt',
        packaging: "as Buyer's request",
        storage: 'Keep in Cool, Dry Place',
        shelf_life: '24 months',
        origin: 'Vietnam'
    }
]