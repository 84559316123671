
const ProductItem = ({ item }) => {
    return (
        <div className="p-2 rounded border hover:border-sky-400 hover:bg-sky-50">

            <img className="w-full h-[180px] object-cover" src={`/products/${item.id}.jpg`} alt={item.name}/>

            <div className="mt-1 font-bold text-[14px] line-clamp-2 h-[34px] leading-tight">{item.name}</div>

            <div className="flex items-center text-xs">
                <div className="grow mr-1">Packaging</div>
                <div className="text-xs text-right">{item.packaging}</div>
            </div>
            <div className="flex items-center text-xs">
                <div className="grow">Storage</div>
                <div>{item.storage}</div>
            </div>
            <div className="flex items-center text-xs">
                <div className="grow">Shelf Life</div>
                <div>{item.shelf_life}</div>
            </div>
            <div className="flex items-center text-xs">
                <div className="grow">Origin</div>
                <div>{item.origin}</div>
            </div>
        </div>
    )
}

const ProductList = ({items, title, listId}) => {

    return <div id={listId} className="w-full mt-6">
        { title && <div className="mb-2 font-bold text-xl text-sky-600 border-b border-sky-300">{title}</div> }
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1 sm:gap-2 md:gap-3">
            {items.map((item, index) => <ProductItem item={item} key={index} />)}
        </div>
    </div>
}

export default ProductList