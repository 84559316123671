import React from 'react';
import { Link, NavLink } from 'react-router-dom';

const NavBar = () => {

    const items = [
        { name: 'Home', path: '/' },
        { name: 'Products', path: '/products' },
        { name: 'About us', path: '/about' },
        { name: 'Contact us', path: '/contact-us' }
    ]

    return <div className='w-full'>
        <div className="bg-slate-100 text-slate-900 p-4">
            <div className="container mx-auto flex justify-between items-center">
                <div>
                    <Link to='/'>
                        <div className='flex items-center'>
                            <img className='h-16 mr-2' src='/logo.png'/>
                            <h1 className="hidden sm:block text:md sm:text-3xl text-sky-700 font-semibold hover:opacity-80">Hatori</h1>
                        </div>
                    </Link>
                </div>
                <div>
                    <ul className="flex space-x-4">
                        { items.map((item, index) => <NavLink to={item.path} key={index}
                            className={({ isActive }) =>
                                "py-2 px-0 sm:px-4 text-sm sm:text-lg font-bold hover:border-b-2 hover:opacity-80 " + (isActive ? "border-b-2 border-sky-600 text-sky-600" : "text-slate-600")
                            }>
                                {item.name}</NavLink>)
                        }
                    </ul>
                </div>
            </div>
        </div>
    </div>
}


export default NavBar;