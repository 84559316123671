

export const items = [
    {
        id: "frozen_product_1",
        name: "Frozen Mango Dices: Xoài Cắt Hạt Lựu",
        packaging: "1kg/ bag, 10 bags/ box",
        size: "20 x 20mm (± 2mm) (as Buyer's request)",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_2",
        name: "Frozen Mango Sticks: Xoài Que",
        packaging: "1kg/ bag, 10 bags/ box",
        size: "40 x 50 x 60mm (± 2mm) (as Buyer's request)",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_3",
        name: "Frozen Jack Fruit: Mít",
        packaging: "250g/ bag, 24 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_4",
        name: "Frozen Japanese Sweet Potato (Steamed): Khoai Lang Vàng (Hấp)",
        packaging: "500g/ bag, 20 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_5",
        name: "Frozen Sweet Potato (Steamed): Khoai Lang Tím (Hấp)",
        packaging: "500g/ bag, 20 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_6",
        name: "Frozen Sliced Taro: Khoai Môn Cắt Lát",
        packaging: "500g/ bag, 24 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_7",
        name: "Frozen Whole Sticky Corn (Cooked): Bắp Nếp Chín",
        packaging: "3 pcs/ bag, 20 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_8",
        name: "Frozen Whole Okra: Đậu Bắp",
        packaging: "250g/ bag, 24 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_9",
        name: "Frozen Banana Leaves: Lá Chuối",
        packaging: "1kg/ bag, 6 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_10",
        name: "Frozen Minced Lemongrass: Sả Bằm",
        packaging: "100g/ bag, 60 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_11",
        name: "Frozen Sliced Lemongrass: Sả Bào",
        packaging: "200g/ bag, 60 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_12",
        name: "Frozen Whole Lemongrass: Sả Khúc",
        packaging: "500g/ bag, 20 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_13",
        name: "Frozen Minced Cassava Leaves: Lá Khoai Mì Xay",
        packaging: "500g/ bag, 24 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_14",
        name: "Frozen Grated Purple Yam: Khoai Mỡ Tím Bào",
        packaging: "200g/ bag, 60 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_15",
        name: "Frozen Casava Chunks: Khoai Mì Cắt Khúc",
        packaging: "500g/ bag, 24 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_16",
        name: "Frozen Bitter Melon Chunks (Without Seeds): Khổ Qua Làm Sạch",
        packaging: "500g/ bag, 20 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_17",
        name: "Frozen Coconut Strip: Dừa Bào Sợi 250G",
        packaging: "250g/ bag, 50 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_18",
        name: "Frozen Sugar cane Sticks: Mía Khúc",
        packaging: "10kgs/ box, (40 - 50cm/ stick) (as Buyer's request)",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_19",
        name: "Frozen Passion Fruit Puree (Without Seeds): Chanh Dây Xay Nhuyễn",
        packaging: "1kg/ bag, 10 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_20",
        name: "Frozen Mango Puree: Xoài Xay Nhuyễn",
        packaging: "1kg/ bag, 10 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_21",
        name: "Frozen Pineapple Puree: Dứa Xay Nhuyễn",
        packaging: "1kg/ bag, 10 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_22",
        name: "Frozen Dragon Fruit White Flesh Puree: Thanh Long Ruột Trắng Xay Nhuyễn",
        packaging: "1kg/ bag, 10 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_23",
        name: "Frozen Dragon Fruit Red Flesh Puree: Thanh Long Ruột Đỏ Xay Nhuyễn",
        packaging: "1kg/ bag, 10 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    },
    {
        id: "frozen_product_24",
        name: "Frozen Soursop Puree: Mãng Cầu Xay Nhuyễn",
        packaging: "1kg/ bag, 10 bags/ box",
        storage: "No higher than 0°F (-18°C)",
        shelf_life: "24 months",
        origin: "Vietnam"
    }
]