
export const items = [
    {
        id: "non_food_1",
        name: 'Sweeping Broom: Chổi Quét Nhà',
        packaging: '1 x 50 pieces/ box',
        storage: 'Keep in Cool, Dry Place',
        origin: 'Vietnam'
    },
    {
        id: "non_food_2",
        name: 'Incense Joss Sticks: Nhang Trầm 40x210g',
        packaging: '210g/ bag, 40 bags/ box',
        storage: 'Keep in Cool, Dry Place',
        origin: 'Vietnam'
    }
]