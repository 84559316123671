


const HomePage = () => {
    return <div className="pt-4">
        <img className="w-full max-w-[100vw] border"  src='/banner/home.png' alt='Haroti'></img>

        <div className="mt-4 px-2 sm:px-0">
            <p>
            <b>HATORI Import Export Co.,Ltd</b> is one of the leading exporters and suppliers of Asian Foods & Beverage in Vietnam. We are happy to supply well branded and high-quality products to all wholesalers and retailers over 20 countries around the world. Our excellence lies in excellent customer service, top-notch quality products with the most competitive price. Having experience in Asian products, our sales team always focuses on customer demands and offers fast customized service to clients: <b>Asian & European supermarkets, wholesalers, Oceania and Islands etc.</b>
            </p>

            <div className="mt-6 font-bold text-xl">Our core values:</div>
            <p>
            Professionally equipped by an experienced sales & sourcing team to smoothen your purchase journey with peace of mind from reasonably good prices of top-notch products’ quality to innovatively fast delivery. We aspire to bring a great variety of products from fresh, frozen and dry goods to fulfill your food shelves. Your satisfaction makes our day.
            </p>

            <div className="mt-6 font-bold text-xl">From our great variety/ quality of products to your fulfilled food shelf</div>
            <p>
            We - HATORI Import Export Co.,Ltd - are proud to be your most innovative food supplier of Asian products.
            </p>
        </div>
    </div>
}

export default HomePage