import ProductList from "../components/ProductList"
import { useState } from "react"

import { items as fresh_products } from "../data/fresh_products"
import { items as frozen_products } from "../data/frozen_products"
import { items as dried_products } from "../data/dried_products"
import { items as canned_products } from "../data/canned_products"
import { items as drink_products } from "../data/drink_products"
import { items as other_products } from "../data/other_products"
import { items as non_products } from "../data/non_products"

const ProductsPage = () => {
    const [freshProducts] = useState(fresh_products)
    const [frozenProducts] = useState(frozen_products)
    const [driedProducts] = useState(dried_products)
    const [cannedProducts] = useState(canned_products)
    const [drinkProducts] = useState(drink_products)
    const [otherProducts] = useState(other_products)
    const [nonProducts] = useState(non_products)

    return <div className="w-full p-2">
            <div className="text-3xl text-bold mb-4">Products</div>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-1 sm:gap-2 md:gap-3">
                <a href="#fresh"
                    className="border border-sky-200 text-sky-500 rounded px-2 py-1 flex justify-center items-center hover:bg-sky-50 cursor-pointer">
                    Fresh Products({freshProducts.length})
                </a>

                <a href="#frozen"
                    className="border border-sky-200 text-sky-500 rounded px-2 py-1 flex justify-center items-center hover:bg-sky-50 cursor-pointer">
                    Frozens Products({frozenProducts.length})
                </a>

                <a href="#dried"
                    className="border border-sky-200 text-sky-500 rounded px-2 py-1 flex justify-center items-center hover:bg-sky-50 cursor-pointer">
                    Dried Products({driedProducts.length})
                </a>

                <a href="#canned"
                    className="border border-sky-200 text-sky-500 rounded px-2 py-1 flex justify-center items-center hover:bg-sky-50 cursor-pointer">
                    Canned Products({cannedProducts.length})
                </a>

                <a href="#drink"
                    className="border border-sky-200 text-sky-500 rounded px-2 py-1 flex justify-center items-center hover:bg-sky-50 cursor-pointer">
                    Drink Products({drinkProducts.length})
                </a>

                <a href="#other"
                    className="border border-sky-200 text-sky-500 rounded px-2 py-1 flex justify-center items-center hover:bg-sky-50 cursor-pointer">
                    Other Products({otherProducts.length})
                </a>

                <a href="#non"
                    className="border border-sky-200 text-sky-500 rounded px-2 py-1 flex justify-center items-center hover:bg-sky-50 cursor-pointer">
                    Non Products({nonProducts.length})
                </a>

            </div>

            <ProductList items={freshProducts} title="Fresh Products" listId="fresh"/>
            <ProductList items={frozenProducts} title="Frozens Products" listId="frozen"/>
            <ProductList items={driedProducts} title="Dried Products" listId="dried"/>
            <ProductList items={cannedProducts} title="Canned Products" listId="canned"/>
            <ProductList items={drinkProducts} title="Drink Products" listId="drink"/>
            <ProductList items={otherProducts} title="Other Products" listId="other"/>
            <ProductList items={nonProducts} title="Non Products" listId="non"/>
        </div>
}

export default ProductsPage