const AboutPage = () => {

    return <div className="py-4 px-2 sm:px-0">
        <div className="flex items-center justify-center">
            <img className="w-full max-w-[600px] mb-4" src='/about-us.png' alt='Haroti - About Us'></img>
        </div>
        
        <p>
        <b>HATORI Import Export Co.,Ltd</b> is one of the leading exporters and suppliers of Asian Foods & Beverage in Vietnam. We are happy to supply well branded and high-quality products to all wholesalers and retailers over 20 countries around the world. Our excellence lies in excellent customer service, top-notch quality products with the most competitive price. Having experience in Asian products, our sales team always focuses on customer demands and offers fast customized service to clients: <b>Asian & European supermarkets, wholesalers, Oceania and Islands etc.</b>
        </p>

        <div className="mt-6 font-bold text-xl">Our core values:</div>
        <p>
        Professionally equipped by an experienced sales & sourcing team to smoothen your purchase journey with peace of mind from reasonably good prices of top-notch products’ quality to innovatively fast delivery. We aspire to bring a great variety of products from fresh, frozen and dry goods to fulfill your food shelves. Your satisfaction makes our day.
        </p>

        <div className="mt-6 font-bold text-xl">From our great variety/ quality of products to your fulfilled food shelf</div>
        <p>
        We - HATORI Import Export Co.,Ltd - are proud to be your most innovative food supplier of Asian products.
        </p>
        
        <p className="mt-4">
        We are highly aware that the client's trust is built over the course of time. To have a firm foundation of our client’s reliability, in the long run, we always ensure the highest quality and hygiene of the goods imported and exported. Having been doing business with high-reputed foods manufacturers in Vietnam, we are committed to bringing the best offers to our clients with the perfect guarantee.
        </p>

        <div className="mt-6 font-bold text-xl">From our professional experience to your prosperous business</div>
        <p>
        HATORI Import Export Co.,Ltd is an agile Asian food trading enterprise based in Ho Chi Minh City, Vietnam. Over the years, we are proud to have been doing business with reliable partners and key players in the food industry in Asia and Europe. With our international expertise, we aspire to help your business prosper.
        </p>

        <p className="mt-4">
        At HATORI Import Export Co.,Ltd, we love what we do, which motivates us to make our clients utmost satisfied with any purchases. Moreover, we always have our clients’ best interest at heart every step of the buying process from giving personalized advice, recommending most sold products, arranging efficient transportation and safe payment methods. Simply, we believe in working hard to grow better together with our clients today and every day.
        </p>

        <div className="mt-6 font-bold text-xl">From our innovative transportation to your agile delivery</div>
        <p>
        We actively aim to deliver top-notch products at a reasonable price at your earliest request. Having contacts with many reliable transport companies, we are confident to provide flexible delivery with the most efficient logistics.
        </p>
    </div>
}

export default AboutPage